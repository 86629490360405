
import { createStore } from 'vuex'

// 引入插件
import createPersistedState from "vuex-persistedstate"

export default createStore({
  state: {
    // 个人账户信息
    userInfo: {
      token: "",
      user_id: ""
    },
    // 个人基础信息
    userData: {
      email: '',
      head_image: '',
      nickname: '',
      username: ''
    },

    user_balance: 0, // 用户资产
    cart_num: 0, // cart 未兑换数量
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setUserData(state, data) {
      state.userData = data;
    },
    setUser_balance(state, data) {
      state.user_balance = data;
    },
    setCart_num(state, data) {
      state.cart_num = data;
    },
  },
  actions: {
    setUserInfo(context, data) {
      context.commit("setUserInfo", data)
    },
    setUserData(context, data) {
      context.commit("setUserData", data)
    },
    setUser_balance(context, data) {
      context.commit("setUser_balance", data)
    },
    setCart_num(context, data) {
      context.commit("setCart_num", data)
    },
  },
  // vuex数据持久化配置 
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
    })
  ]
})
